import { login as loginApi } from '@/api/login'
import { Message } from '@arco-design/web-vue'
import router from '@/router'
import { setTokenTime } from '@/utils/auth'
export default {
  namespaced: true,
  state: () => ({
    token: localStorage.getItem('token') || '',
    menuList: localStorage.getItem('menuList')
      ? JSON.parse(localStorage.getItem('menuList'))
      : [],
    siderType: true,
    lang: localStorage.getItem('lang') || 'zh'
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    setMenu(state, menuList) {
      state.menuList = menuList
      localStorage.setItem('menuList', JSON.stringify(menuList))
    },
    changeSiderType(state) {
      state.siderType = !state.siderType
    },
    changLang(state, lang) {
      state.lang = lang
    }
  },
  actions: {
    login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginApi(userInfo)
          .then((res) => {
            console.log(res.menus)
            if (res.accesstoken) {
              commit('setToken', res.accesstoken)
              // commit('setMenu', res.menus ? res.menus : []) // 将登录接口返回的菜单列表显示到页面上
              const menuList = [
                {
                  id: 100,
                  authName: '业绩管理',
                  path: 'performance',
                  children: [
                    {
                      id: 1001,
                      authName: '业绩统计',
                      path: 'performance',
                      children: []
                    }
                  ]
                },
                {
                  id: 101,
                  authName: '业务管理',
                  path: 'orderlistserve',
                  children: [
                    {
                      id: 1011,
                      authName: '服务订单列表',
                      path: 'orderlistserve',
                      children: []
                    },
                    {
                      id: 1012,
                      authName: '售后列表',
                      path: 'afterSales',
                      children: []
                    }
                  ]
                },
                {
                  id: 102,
                  authName: '订单管理',
                  path: 'orderlistserve',
                  children: [
                    {
                      id: 1021,
                      authName: '服务订单列表',
                      path: 'serviceOrders',
                      children: []
                    },
                    {
                      id: 1022,
                      authName: '商品订单列表',
                      path: 'orders',
                      children: []
                    }
                  ]
                },
                {
                  id: 103,
                  authName: '客户管理',
                  path: 'device',
                  children: [
                    {
                      id: 1031,
                      authName: '客户列表',
                      path: 'device',
                      children: []
                    }
                  ]
                },
                {
                  id: 104,
                  authName: '商品管理',
                  path: 'goods',
                  children: [
                    {
                      id: 1041,
                      authName: '商品列表',
                      path: 'goods',
                      children: []
                    }
                  ]
                },
                {
                  id: 105,
                  authName: '师傅管理',
                  path: 'afterSales',
                  children: [
                    {
                      id: 1051,
                      authName: '师傅列表',
                      path: 'masterManagement',
                      children: []
                    }
                  ]
                },
                {
                  id: 106,
                  authName: 'chatGPT',
                  path: 'chatGPT',
                  children: [
                    {
                      id: 1061,
                      authName: 'chatGPT',
                      path: 'chatGPT',
                      children: []
                    }
                  ]
                },
                {
                  id: 107,
                  authName: '权限管理',
                  path: 'role',
                  children: [
                    {
                      id: 1071,
                      authName: '角色组管理',
                      path: 'role',
                      children: []
                    },
                    {
                      id: 1072,
                      authName: '管理员管理',
                      path: 'Administrator',
                      children: []
                    },
                    {
                      id: 1073,
                      authName: '菜单规则管理',
                      path: 'MenuRule',
                      children: []
                    },
                    {
                      id: 1074,
                      authName: '管理员日志',
                      path: 'AdministratorLog',
                      children: []
                    }
                  ]
                }

              ]
              commit('setMenu', menuList || [])
              setTokenTime()
              router.replace('/')
            } else {
              Message.error('账号密码错误！')
            }
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 退出
    logout({ commit }) {
      commit('setToken', '')
      sessionStorage.setItem('path', '/orders')
      localStorage.clear()
      window.location.reload()
      // router.push('/login')
    }
  }
}
