import { createRouter, createWebHashHistory } from 'vue-router'

// 定义一个白名单数组，动态生成路由
const webSiteRouter = []
export const webSitLists = [
  'home',
  'productOne',
  'productTwo',
  'applicationPhone',
  'applicationMini',
  'companyNews',
  'contactUs',
  'privacyPolicy',
  'license'
]

webSitLists.forEach((item) => {
  webSiteRouter.push({
    path: '/' + item,
    name: item,
    component: () => import('../views/pages/' + item)
  })
})

// 创建路由对象

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName:"login" */ '../views/login')
  },
  {
    path: '/',
    name: '/',
    component: () => import('../layout'),
    redirect: '/orders',
    children: [

      {
        path: 'chatGPT',
        name: 'chatGPT',
        component: () =>
          import(/* webpackChunkName:"users" */ '@/views/chatGPT/index.vue')
      },
      {
        path: 'users',
        name: 'users',
        component: () =>
          import(/* webpackChunkName:"users" */ '@/views/users/index.vue')
      },
      {
        path: 'categories',
        name: 'categories',
        component: () =>
          import(
            /* webpackChunkName:"categories" */ '@/views/categories/index.vue'
          )
      },
      {
        path: 'goods',
        name: 'goods',
        component: () =>
          import(/* webpackChunkName:"goods" */ '@/views/goods/index.vue')
      },
      {
        path: 'afterSales',
        name: 'afterSales',
        component: () =>
          import(/* webpackChunkName:"goods" */ '@/views/afterSales/index.vue')
      },
      {
        path: 'orders',
        name: 'orders',
        component: () =>
          import(/* webpackChunkName:"orders" */ '@/views/orders/index.vue')
      },
      {
        path: 'serviceOrders',
        name: 'serviceOrders',
        component: () =>
          import(
            /* webpackChunkName:"orders" */ '@/views/orders-service/index.vue'
          )
      },

      {
        path: 'device',
        name: 'device',
        component: () =>
          import(/* webpackChunkName:"device" */ '@/views/device/index.vue')
      },
      {
        path: 'params',
        name: 'params',
        component: () =>
          import(/* webpackChunkName:"params" */ '@/views/params/index.vue')
      },
      {
        path: 'reports',
        name: 'reports',
        component: () =>
          import(/* webpackChunkName:"reports" */ '@/views/reports/index.vue')
      },
      {
        path: 'rights',
        name: 'rights',
        component: () =>
          import(/* webpackChunkName:"rights" */ '@/views/rights/index.vue')
      },
      {
        path: 'roles',
        name: 'roles',
        component: () =>
          import(/* webpackChunkName:"roles" */ '@/views/roles/index.vue')
      },
      {
        path: 'masterManagement',
        name: 'masterManagement',
        component: () =>
          import(
            /* webpackChunkName:"roles" */ '@/views/master-manage/index.vue'
          )
      },
      {
        path: 'orderlistserve',
        name: 'orderlistserve',
        component: () =>
          import(
            /* webpackChunkName:"roles" */ '@/views/orderlist-serve/index.vue'
          )
      },
      {
        // 菜单管理
        path: 'menuManagement',
        name: 'menuManagement',
        component: () =>
          import(
            /* webpackChunkName:"roles" */ '@/views/menuManagement/index.vue'
          )
      },
      {
        // 权限管理
        path: 'role',
        name: 'role',
        component: () =>
          import(
            /* webpackChunkName:"roles" 角色组管理 */ '@/views/jurisdiction/role/role.vue'
          )
      },
      {
        // 权限管理
        path: 'Administrator',
        name: 'Administrator',
        component: () =>
          import(
            /* webpackChunkName:"roles" 管理员 */ '@/views/jurisdiction/Administrator/Administrator.vue'
          )
      },
      {
        // 权限管理
        path: 'MenuRule',
        name: 'MenuRule',
        component: () =>
          import(
            /* webpackChunkName:"roles" 菜单规则管理 */ '@/views/jurisdiction/MenuRule/MenuRule.vue'
          )
      },
      {
        // 权限管理
        path: 'AdministratorLog',
        name: 'AdministratorLog',
        component: () =>
          import(
            /* webpackChunkName:"roles" 管理员日志 */ '@/views/jurisdiction/AdministratorLog/AdministratorLog.vue'
          )
      },
      {
        // 业绩管理
        path: 'performance',
        name: 'performance',
        component: () => import(/* webpackChunkName:"login" */ '@/views/performance/index.vue')
      }
    ]
  }

  // {
  //   path: '/performance',
  //   name: 'performance',
  //   component: () => import(/* webpackChunkName:"login" */ '@/views/performance/index.vue'),
  //   children: [{
  //     path: 'chatGPT',
  //     name: 'chatGPT',
  //     component: () =>
  //       import(/* webpackChunkName:"users" */ '@/views/chatGPT/index.vue')
  //   }]
  // }
]
routes.push(...webSiteRouter)

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
