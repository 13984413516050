export default {
  menus: {
    '/': '首页',
    home: '首页',
    users: '用户列表',
    roles: '角色列表',
    rights: '权限列表',
    goods: '商品列表',
    params: '分类参数',
    categories: '商品分类',
    orders: '商品订单列表',
    serviceOrders: '服务订单列表',
    orderlistserve: '服务订单列表',
    reports: '数据报表',
    device: '客户列表',
    afterSales: '售后列表',
    chatGPT: 'ChatGPT',
    masterManagement: '师傅列表',
    menuManagement: '菜单列表',
    role: '角色组列表',
    Administrator: '管理员管理',
    MenuRule: '菜单规则管理',
    AdministratorLog: '管理员日志',
    performance: '业绩统计',
    statistics: '业绩统计'
  },
  login: {
    title: '用户登录',
    btnTitle: '登录'
  },
  dialog: {
    deleteTitle: '确定要删除用户'
  },
  table: {
    username: '姓名',
    email: '邮箱',
    mobile: '手机',
    role_name: '角色',
    mg_state: '状态',
    create_time: '创建时间',
    action: '操作',
    search: '搜索',
    adduser: '添加用户',
    placeholder: '请输入搜索的用户姓名'
  },
  message: {
    updeteSuccess: '更新成功'
  },
  driver: {
    doneBtnText: '完成',
    closeBtnText: '关闭',
    nextBtnText: '下一步',
    prevBtnText: '上一步',
    guideBtn: '引导按钮',
    hamburgerBtn: '汉堡按钮',
    fullScreen: '全屏按钮'
  }
}
